/*
  elm-hot creates an additional div wrapper around the app to make HMR possible.
  This could break styling in development mode if you are using Elm UI.

  More context in the issue:
    https://github.com/halfzebra/create-elm-app/issues/320
*/

@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cuprum:wght@700&display=swap');


[data-elm-hot="true"] {
  height: inherit;
}

body {
  margin: 0;
  text-align: center;
  background-color: white;
  font-family: Cuprum, "Titillium Web", sans-serif;
  user-select: none;
}

.background {
  background-blend-mode: difference;
  background: url(https://source.unsplash.com/collection/388793/) no-repeat center center fixed;
  background-size: cover;
}

h1 {
  font-size: 30px;
  text-transform: uppercase;
}

img {
  margin: 20px 0;
  max-width: 200px;
}

.login {
    animation-name: fadein;
    animation-duration: 4s;
}

@keyframes fadein {
    0% {opacity: 0}
    75% {opacity: 0}
    100% {opacity: 1}
}